import { Badge, Chip } from "@mui/material";
import { now } from "moment";
import { InvestPool, InvestPoolState } from "../../types";
import {
  getInvestPoolStateColor,
  InvestPoolStateText,
} from "../../utils/coimex-utils";
import ChipWithProgress from "../ChipWithProgress/ChipWithProgress";

interface IProps {
  pool: InvestPool;
}

export default function InvestPoolStateChip({ pool }: IProps) {
  const progress = (() => {
    switch (pool.state) {
      case InvestPoolState.NotStarted:
        return (
          ((now() - pool.createdAt) * 100) /
          (pool.investmentOpenAt - pool.createdAt)
        );

      case InvestPoolState.InvestmentStarted:
        return (
          ((now() - pool.investmentOpenAt) * 100) /
          (pool.investmentCloseAt - pool.investmentOpenAt)
        );

      case InvestPoolState.InvestmentClosed:
        return (
          ((now() - pool.investmentCloseAt) * 100) /
          (pool.stakingStartAt - pool.investmentCloseAt)
        );

      case InvestPoolState.StakingStarted:
        return (
          ((now() - pool.stakingStartAt) * 100) /
          (pool.stakingEndAt - pool.stakingStartAt)
        );
    }
  })();

  return progress == null ? (
    <Badge
      badgeContent={
        pool.state === InvestPoolState.StakingEnded ? "Not Settled" : ""
      }
      invisible={pool.state !== InvestPoolState.StakingEnded}
      color="error"
    >
      <Chip
        label={InvestPoolStateText[pool.state]}
        color={getInvestPoolStateColor(pool.state)}
        size="small"
        variant="filled"
      />
    </Badge>
  ) : (
    <ChipWithProgress
      label={InvestPoolStateText[pool.state]}
      color={getInvestPoolStateColor(pool.state)}
      progress={progress}
    />
  );
}
