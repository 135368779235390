import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Button,
  CircularProgress,
  InputAdornment,
  LinearProgress,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { blockchainApi } from "../../../http/blockchain.api";
import { Address, Erc20TokenInfo } from "../../../types";
import {
  isValidBlockchainAddress,
  toDecimalsAmount,
  toShortHash,
} from "../../../utils/blockchain-utils";
import {
  selectCommodityTokens,
  selectIsGetCommodityTokensPending,
} from "../../commodity-tokens/commodity-tokens.selectors";
import {
  selectBlockchainNativeToken,
  selectCommodityExchangeTokens,
  selectIsGetConfigsPending,
} from "../../settings/settings.selectors";
import useTransferToken from "../hooks/useTransferToken";

interface IProps {
  to?: string;
  tokenAddress?: string;
  amount?: number;
  close?: (successful?: boolean) => void;
}

interface FormProps {
  to: string;
  amount: number;
  tokenAddress: string;
}

export default function TokenTransferForm(params: IProps) {
  const nativeToken = useSelector(selectBlockchainNativeToken);
  const commodityExchangeTokens = useSelector(selectCommodityExchangeTokens);
  const commodityTokens = useSelector(selectCommodityTokens);
  const isGetConfigsPending = useSelector(selectIsGetConfigsPending);
  const isGetCommodityTokensPending = useSelector(
    selectIsGetCommodityTokensPending
  );
  const tokens = useMemo(
    () =>
      (
        [
          nativeToken,
          ...commodityExchangeTokens,
          ...commodityTokens,
        ] as Erc20TokenInfo[]
      )
        .filter(Boolean)
        .distinct((t) => t.address),
    [nativeToken, commodityExchangeTokens, commodityTokens]
  );
  const [selectedToken, setSelectedToken] = useState<Erc20TokenInfo>();
  const [isGetSelectedTokenPending, setIsGetSelectedTokenPending] =
    useState(false);
  const { transferToken, isPending, isSuccess } = useTransferToken();
  const validationSchema = Yup.object().shape({
    to: Yup.string()
      .required()
      .test("valid", function (value) {
        return isValidBlockchainAddress(value);
      }),
    amount: Yup.number().required().positive(),
    tokenAddress: Yup.string()
      .required()
      .test("valid", function (value) {
        return value == nativeToken?.address || isValidBlockchainAddress(value);
      }),
  });
  const form = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      to: "",
      amount: "",
      tokenAddress: "",
    },
  });
  const { enqueueSnackbar } = useSnackbar();
  const amount = form.watch("amount");
  const tokenAddress = form.watch("tokenAddress");
  const to = form.watch("to");
  const token = tokens?.find((t) => t.address == tokenAddress);

  const getSearchedToken = async (tokenAddress: Address) => {
    try {
      setIsGetSelectedTokenPending(true);
      const token = tokens.find((t) => t.address == tokenAddress);
      if (token) {
        setSelectedToken(token);
      } else {
        setSelectedToken(null);
        if (isValidBlockchainAddress(tokenAddress)) {
          setSelectedToken(await blockchainApi.getTokenInfo(tokenAddress));
        }
      }
    } finally {
      setIsGetSelectedTokenPending(false);
    }
  };

  const patchFormValue = (key, value) => {
    form.setValue(key, value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  const onSubmit = async ({ to, amount, tokenAddress }: FormProps) => {
    transferToken(
      to,
      tokenAddress,
      BigInt(toDecimalsAmount(amount, token.decimals))
    );
  };

  useEffect(() => {
    if (tokenAddress) {
      getSearchedToken(tokenAddress);
    }
  }, [tokenAddress, tokens]);

  useEffect(() => {
    params.to && patchFormValue("to", params.to);
    params.tokenAddress && patchFormValue("tokenAddress", params.tokenAddress);
    params.amount && patchFormValue("amount", params.amount);
  }, [params.to, params.tokenAddress, params.amount]);

  useEffect(() => {
    if (isSuccess) {
      params.close(true);
      enqueueSnackbar(
        `Transferred ${amount} ${token.symbol} to ${toShortHash(to)}`,
        {
          variant: "info",
        }
      );
    }
  }, [isSuccess]);

  return (
    <form
      className="p-4 w-[490px]"
      onSubmit={form.handleSubmit(onSubmit as any)}
    >
      <header className="modal-header text-lg">Transfer Token</header>

      <div className="flex flex-col gap-6">
        <TextField
          className="w-full"
          {...form.register("to")}
          label="To"
          variant="filled"
          size="small"
          helperText="Account address (must start with ‘0x’)"
          error={!!form.formState.errors?.to}
          required
        />

        <div className="flex gap-1">
          <TextField
            className="flex-1"
            {...form.register("amount")}
            inputMode="decimal"
            label="Amount"
            variant="filled"
            size="small"
            error={!!form.formState.errors?.amount}
            autoFocus
            required
          />

          <Autocomplete
            className="flex-[2]"
            value={form.watch("tokenAddress")}
            onChange={(_, value) => patchFormValue("tokenAddress", value)}
            freeSolo
            options={tokens.map((t) => t.address)}
            renderInput={(params) => (
              <div className="relative">
                <TextField
                  {...params}
                  label="Token"
                  variant="filled"
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment className="-mr-7" position="end">
                        {isGetSelectedTokenPending ? (
                          <CircularProgress size={12} />
                        ) : (
                          selectedToken?.symbol
                        )}
                      </InputAdornment>
                    ),
                  }}
                  error={!!form.formState.errors?.tokenAddress}
                  helperText="Token address must start with ‘0x’"
                  onBlur={(e) => patchFormValue("tokenAddress", e.target.value)}
                  required
                />
                {isGetConfigsPending ||
                  (isGetCommodityTokensPending && (
                    <LinearProgress className="absolute top-0 left-0 right-0 h-0.5" />
                  ))}
              </div>
            )}
            renderOption={(props, option) => {
              const { key, ...optionProps } = props;
              const symbol = tokens.find((t) => t.address == option)?.symbol;
              return (
                <li
                  key={key}
                  {...optionProps}
                  className="p-2 flex justify-between hover:bg-hover cursor-pointer"
                >
                  {symbol ?? option}
                </li>
              );
            }}
          />
        </div>
      </div>

      <footer className="modal-footer flex flex-row-reverse gap-4">
        <LoadingButton type="submit" variant="contained" loading={isPending}>
          Transfer
        </LoadingButton>

        <Button
          type="button"
          variant="text"
          onClick={() => params.close(false)}
        >
          Cancel
        </Button>
      </footer>
    </form>
  );
}
