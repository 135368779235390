import { CircularProgress } from "@mui/material";
import classNames from "classnames";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NoRowsOverlay from "../../../components/NoRowsOverlay/NoRowsOverlay";
import { investApi } from "../../../http";
import { CloseModal } from "../../../providers/ModalProvider";
import { InvestPoolPaybackTx, TokenTransferTx } from "../../../types";
import {
  toBlockchainAddressUrl,
  toBlockchainTxUrl,
  toShortHash,
} from "../../../utils/blockchain-utils";
import { BlockchainTxStatus } from "../../blockchain/types";
import { selectBlockchainConfigs } from "../../settings/settings.selectors";

interface IProps {
  id: InvestPoolPaybackTx["id"];
  close: CloseModal<void>;
}

export default function InvestPoolPaybackTokenTransferTxs({
  id,
  close,
}: IProps) {
  const [txs, setTxs] = useState<TokenTransferTx[]>([]);
  const [isGetTxsPending, setIsGetTxsPending] = useState(true);
  const config = useSelector(selectBlockchainConfigs);

  const getTxs = async () => {
    try {
      setIsGetTxsPending(true);
      setTxs(await investApi.getInvestPoolPaybackTokenTransferTxs(id));
      setIsGetTxsPending(false);
    } catch (err) {
      close();
    }
  };

  useEffect(() => {
    getTxs();
  }, [id]);

  return (
    <div
      className="InvestPoolInvestTxs max-w-full p-4"
      style={{ width: "700px", minHeight: "140px" }}
    >
      <header className="modal-header flex justify-between items-center pr-12">
        Invest Pool Payback Transactions
      </header>
      {!isGetTxsPending ? (
        <div
          className="flex-1 overflow-auto flex flex-col gap-4"
          style={{ maxHeight: "calc(100vh - 200px)" }}
        >
          {txs?.length ? (
            <div className="flex flex-col gap-2">
              <header className="sticky top-0 bg-bg txs-list-row">
                <span className="txs-list-row__col">Time</span>
                <span className="txs-list-row__col">To</span>
                <span className="txs-list-row__col">Amount</span>
                <span className="txs-list-row__col">Status</span>
                <span className="txs-list-row__col">TxHash</span>
              </header>
              {txs?.map((tx, idx) => (
                <div key={idx} className="txs-list-row">
                  <span className="txs-list-row__col">
                    {moment(tx.blockTimestamp * 1000).fromNow()}
                  </span>
                  <span className="txs-list-row__col">
                    <a
                      className="link"
                      href={toBlockchainAddressUrl(
                        config.addressExplorerUrl,
                        tx.to
                      )}
                      target="_blank"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {toShortHash(tx.to)}
                    </a>
                  </span>
                  <span className="txs-list-row__col">
                    {tx.valueFormatted} {tx.tokenSymbol}
                  </span>
                  <span
                    className={classNames("txs-list-row__col", {
                      "text-yellow-500":
                        tx.txStatus == BlockchainTxStatus.Pending,
                      "text-red-500": tx.txStatus == BlockchainTxStatus.Failed,
                    })}
                  >
                    {tx.txStatus}
                  </span>
                  <span className="txs-list-row__col">
                    <a
                      className="link"
                      href={toBlockchainTxUrl(config.txExplorerUrl, tx.txHash)}
                      target="_blank"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {toShortHash(tx.txHash)}
                    </a>
                  </span>
                </div>
              ))}
            </div>
          ) : (
            <NoRowsOverlay emptyMessage="No transactions" />
          )}
        </div>
      ) : (
        <div className="absolute left-0 right-0 top-[80px] flex justify-center">
          <CircularProgress color="primary" size="32px" />
        </div>
      )}
    </div>
  );
}
