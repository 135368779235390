import { Edit } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { IconButton, Paper } from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useSelector } from "react-redux";
import CopyToClipboard from "../../../components/CopyToClipboard/CopyToClipboard";
import InvestPoolStateChip from "../../../components/InvestPoolStateChip/InvestPoolStateChip";
import InvestPoolStatusIndicator from "../../../components/InvestPoolStatusIndicator/InvestPoolStatusIndicator";
import { investApi } from "../../../http";
import { useModal } from "../../../providers/ModalProvider";
import { InvestPool, InvestPoolStatus } from "../../../types";
import { toBlockchainAddressUrl } from "../../../utils/blockchain-utils";
import { selectBlockchainConfigs } from "../../settings/settings.selectors";
import PromptPayPoolFormProvider from "../providers/PromptPayPoolFormProvider";
import PromptPayPoolForm from "./PromptPayPoolForm";

interface IProps {
  pool: InvestPool;
  onUpdate: (update: Partial<InvestPool>) => void;
}

export default function InvestPoolInfo({ pool, onUpdate }: IProps) {
  const configs = useSelector(selectBlockchainConfigs);
  const [isPublishing, setIsPublishing] = useState(false);
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const openInvestPoolEditForm = async () => {
    const investPoolUpdated = await openModal(
      (props) => (
        <PromptPayPoolFormProvider opts={{ pool }} {...props}>
          <PromptPayPoolForm />
        </PromptPayPoolFormProvider>
      ),
      {
        hideCloseButton: false,
      }
    );
    console.log(investPoolUpdated);
    investPoolUpdated && onUpdate(investPoolUpdated);
  };

  const publishToBlockchain = async () => {
    try {
      setIsPublishing(true);
      await investApi.publishInvestPool(pool.id);
      enqueueSnackbar(`Invest Pool published successfully.`, {
        variant: "info",
      });
      onUpdate({ status: InvestPoolStatus.Published });
      enqueueSnackbar("Invest pool published to blockchain successfully.", {
        variant: "info",
      });
    } finally {
      setIsPublishing(false);
    }
  };

  const renderEditButton = () =>
    pool.status === InvestPoolStatus.Draft && (
      <IconButton
        className="-mt-1"
        size="small"
        onClick={openInvestPoolEditForm}
      >
        <Edit fontSize="small" />
      </IconButton>
    );

  return (
    <div className="max-w-[44rem] mx-auto flex flex-col">
      <div
        className="flex-1 overflow-auto"
        style={{
          maxHeight: `calc(100vh - ${
            pool.status === InvestPoolStatus.Draft ? "240" : "190"
          }px)`,
        }}
      >
        <Paper className="flex flex-col gap-y-6 p-12 card-border" elevation={0}>
          {/* invest-pool information */}
          <section className="invest-pool-info-section">
            <div className="flex justify-between items-center mb-2">
              <div className="flex gap-2">
                <h2 className="text-lg font-medium">Pool Information</h2>
                {renderEditButton()}
              </div>
              <InvestPoolStatusIndicator status={pool.status} />
            </div>

            <div className="p-3 flex flex-col gap-4">
              <div className="info-section-item">
                <span>Pool Name</span>
                <span className="info-section-item__value">{pool.name}</span>
              </div>

              <div className="info-section-item">
                <span>Pool Duration</span>
                <span className="info-section-item__value">
                  {pool.tenureInDays} Days
                </span>
              </div>

              <div className="info-section-item">
                <span>Pool Wallet Address</span>
                <span className="info-section-item__value">
                  <a
                    className="link"
                    href={toBlockchainAddressUrl(
                      configs.addressExplorerUrl,
                      pool.depositAddress
                    )}
                    target="_blank"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {pool.depositAddress}
                  </a>
                  <CopyToClipboard
                    text={pool.depositAddress}
                    defaultTooltip="Copy Address"
                  />
                </span>
              </div>

              <div className="info-section-item">
                <span>Investment Token</span>
                <span className="info-section-item__value">
                  {pool.investToken.symbol}
                </span>
              </div>

              <div className="info-section-item">
                <span>Minimum Return Rate</span>
                <span className="info-section-item__value">
                  {pool.interestRatePercentage}%
                </span>
              </div>
            </div>
          </section>

          {/* invest-pool state */}
          <section className="invest-pool-info-section">
            <div className="flex justify-between items-center mb-2">
              <div className="flex gap-2">
                <h2 className="text-lg font-medium">Pool State</h2>
                {renderEditButton()}
              </div>
              <InvestPoolStateChip pool={pool} />
            </div>

            <div className="p-3 flex flex-col gap-4">
              <div className="info-section-item">
                <span>Investment Open At</span>
                <span className="info-section-item__value">
                  {moment(pool.investmentOpenAt).format("YYYY-MM-DD hh:mm A")} (
                  {moment(pool.investmentOpenAt).fromNow()})
                </span>
              </div>

              <div className="info-section-item">
                <span>Investment Close At</span>
                <span className="info-section-item__value">
                  {moment(pool.investmentCloseAt).format("YYYY-MM-DD hh:mm A")}{" "}
                  ({moment(pool.investmentCloseAt).fromNow()})
                </span>
              </div>

              <div className="info-section-item">
                <span>Staking Start At</span>
                <span className="info-section-item__value">
                  {moment(pool.stakingStartAt).format("YYYY-MM-DD hh:mm A")} (
                  {moment(pool.stakingStartAt).fromNow()})
                </span>
              </div>

              <div className="info-section-item">
                <span>Staking End At</span>
                <span className="info-section-item__value">
                  {moment(pool.stakingEndAt).format("YYYY-MM-DD hh:mm A")} (
                  {moment(pool.stakingEndAt).fromNow()})
                </span>
              </div>
            </div>
          </section>
        </Paper>
      </div>

      {pool.status === InvestPoolStatus.Draft && (
        <div className="px-4 mt-4 flex justify-end">
          <LoadingButton
            variant="contained"
            color="secondary"
            size="small"
            loading={isPublishing}
            onClick={publishToBlockchain}
          >
            Publish to Blockchain
          </LoadingButton>
        </div>
      )}
    </div>
  );
}
