import {
  CancelOutlined,
  MoreVert,
  OpenInNew,
  Payments,
} from "@mui/icons-material";
import { Button, CircularProgress, IconButton, Switch } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useAccount } from "wagmi";
import MoreMenu from "../../../components/MenuMore/MoreMenu";
import NoRowsOverlay from "../../../components/NoRowsOverlay/NoRowsOverlay";
import { useModal } from "../../../providers/ModalProvider";
import {
  InvestPool,
  InvestPoolInvests,
  InvestPoolPaybackTx,
  InvestPoolState,
  TokenTransferTx,
} from "../../../types";
import {
  fromDecimalsAmount,
  toShortHash,
} from "../../../utils/blockchain-utils";
import { formatNumberWithSeparator } from "../../../utils/number-utils";
import BlockchainAddressBook from "../../blockchain/components/BlockchainAddressBook";
import { BlockchainTxStatus } from "../../blockchain/types";
import { useTokenTransferModal } from "../../treasury/providers/TokenTransferModalProvider";
import InvestPoolInvestTxs from "./InvestPoolInvestTxs";
import { investApi } from "../../../http";
import InvestPoolPaybackTokenTransferTxs from "./InvestPoolPaybackTokenTransferTxs";

interface IProps {
  investPool: InvestPool;
  txs: InvestPoolInvests["txs"];
  onUpdate: (update: Partial<InvestPool>) => void;
  className?: string;
}

export default function InvestPoolInvestsTable({
  investPool,
  txs,
  onUpdate,
  className,
}: IProps) {
  const { address } = useAccount();
  const [rows, setRows] = useState([]);
  const [paybackTxs, setPaybackTxs] = useState<InvestPoolPaybackTx[]>([]);
  const [isGetPaybackTxsPending, setIsGetPaybackTxsPending] = useState(true);
  const [selectedMenuTx, setSelectedMenuTx] = useState<IProps["txs"][0] | null>(
    null
  );
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );
  const { openModal } = useModal();
  const { openTransferModal } = useTokenTransferModal();
  const { enqueueSnackbar } = useSnackbar();
  const isAllInvestTxsPayedBack = useMemo(
    () =>
      txs.every((tx) =>
        paybackTxs.some((paybackTx) => paybackTx.to === tx.from)
      ),
    [txs, paybackTxs]
  );

  const getPaybackTxs = async () => {
    try {
      setIsGetPaybackTxsPending(true);
      setPaybackTxs(await investApi.getInvestPoolPaybackTxs(investPool.id));
    } finally {
      setIsGetPaybackTxsPending(false);
    }
  };

  const handleTransactionsClick = (txIds: TokenTransferTx["id"][]) => {
    openModal((props) => <InvestPoolInvestTxs {...props} ids={txIds} />, {
      closeOnClickOutside: true,
      hideCloseButton: false,
    });
  };

  const handlePaybackClick = (id: InvestPoolPaybackTx["id"]) => {
    openModal(
      (props) => <InvestPoolPaybackTokenTransferTxs {...props} id={id} />,
      {
        closeOnClickOutside: true,
        hideCloseButton: false,
      }
    );
  };

  const handlePayInvestor = async () => {
    closeMenu();

    if (address !== investPool.depositAddress) {
      enqueueSnackbar(
        `Connected wallet account should be ${toShortHash(
          investPool.depositAddress
        )}`,
        {
          variant: "error",
        }
      );
      return;
    }

    if (
      await openTransferModal({
        tokenAddress: investPool.investToken.address,
        to: selectedMenuTx.from,
        amount:
          fromDecimalsAmount(
            BigInt(selectedMenuTx.value),
            investPool.investToken.decimals
          ) *
          (1 + investPool.interestRatePercentage / 100),
      })
    ) {
      getPaybackTxs();
    }
  };

  const handleSettleInvestPool = async (isSettled: boolean) => {
    const updatedInvestPool = await investApi.settleInvestPool(
      investPool.id,
      isSettled
    );
    onUpdate(updatedInvestPool);
  };

  const showMenu = (
    e: React.MouseEvent<HTMLButtonElement>,
    tx: IProps["txs"][0]
  ) => {
    e.stopPropagation();
    setSelectedMenuTx(tx);
    setMenuAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  const cols: GridColDef[] = useMemo(
    () =>
      [
        {
          field: "rowNo",
          headerName: "",
          width: 50,
          sortable: false,
        },
        {
          field: "from",
          headerName: "From",
          renderCell: ({ value }) => <BlockchainAddressBook address={value} />,
          flex: 1,
        },
        {
          field: "amount",
          headerName: "Total Invest",
          renderCell: ({ row }: { row: (typeof txs)[0] }) =>
            `${formatNumberWithSeparator(row.valueFormatted)} ${
              row.tokenSymbol
            }`,
          flex: 1,
        },
        {
          field: "status",
          headerName: "Status",
          renderCell: ({ value }) => (
            <span
              className={classNames("txs-list-row__col", {
                "text-yellow-500": value == BlockchainTxStatus.Pending,
                "text-red-500": value == BlockchainTxStatus.Failed,
              })}
            >
              {value}
            </span>
          ),
          flex: 1,
        },
        {
          field: "txs",
          headerName: "Invest Txs",
          renderCell: ({ row }: { row: (typeof txs)[0] }) => (
            <OpenInNew
              className="link"
              fontSize="small"
              onClick={() => handleTransactionsClick(row.ids)}
            />
          ),
          headerAlign: "center",
          align: "center",
          width: 100,
          sortable: false,
        },
        {
          field: "payback",
          headerName: "Payback",
          renderCell: ({ row }: { row: (typeof txs)[0] }) => {
            const paybackTx = paybackTxs.find((tx) => tx.to === row.from);

            return isGetPaybackTxsPending ? (
              <CircularProgress className="mr-5" size={16} />
            ) : paybackTx ? (
              <div className="flex items-center gap-2">
                <span>
                  {formatNumberWithSeparator(paybackTx?.totalAmount || 0)}{" "}
                  <sub>{row.tokenSymbol}?</sub>
                </span>
                <OpenInNew
                  className="link"
                  fontSize="small"
                  onClick={() => handlePaybackClick(paybackTx.id)}
                />
              </div>
            ) : (
              <span className="mr-6">-</span>
            );
          },
          headerAlign: "right",
          align: "right",
          width: 200,
        },
        {
          field: "",
          renderCell: (params) => (
            <IconButton onClick={(e) => showMenu(e, params.row)}>
              <MoreVert />
            </IconButton>
          ),
          width: 60,
          align: "right",
          sortable: false,
        },
      ] as GridColDef[],
    [isGetPaybackTxsPending, paybackTxs]
  );

  useEffect(() => {
    setRows(
      (txs?.map((tx, index) => ({
        ...tx,
        rowNo: index + 1,
      })) as any) || []
    );
  }, [txs]);

  useEffect(() => {
    getPaybackTxs();
  }, [investPool.id]);

  return (
    <div className="relative">
      <DataGrid
        className={className}
        sx={{
          "& .MuiDataGrid-virtualScroller": {
            maxHeight: "calc(100vh - 490px)",
            overflowX: "hidden",
          },
        }}
        columns={cols}
        rows={rows}
        getRowId={({ from }) => from}
        autoHeight
        disableColumnMenu
        disableSelectionOnClick
        components={{
          NoRowsOverlay: () => <NoRowsOverlay emptyMessage="No Investments" />,
        }}
      />

      {(investPool.state == InvestPoolState.StakingEnded ||
        investPool.state == InvestPoolState.Settled) &&
        isAllInvestTxsPayedBack && (
          <div className="absolute -top-9 -right-4 flex justify-end items-center">
            All investments are payed back?{" "}
            <Switch
              checked={investPool.isSettled}
              onChange={(_, checked) => {
                handleSettleInvestPool(checked);
              }}
            />
          </div>
        )}

      <MoreMenu anchorEl={menuAnchorEl} onClose={closeMenu}>
        <Button
          className="justify-start"
          variant="text"
          color="darkGold"
          startIcon={<Payments />}
          disabled={investPool.state !== InvestPoolState.StakingEnded}
          onClick={handlePayInvestor}
        >
          Payback Investor
        </Button>
      </MoreMenu>
    </div>
  );
}
